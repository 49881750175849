import React, { useState } from "react";
import ModalVideo from "react-modal-video";

import BannerShape from "../assets/images/shapes/banner-shape-1-1.png";
import BannerBG from "../assets/images/resources/banner-image-1-1.jpg";
import BannerMoc from "../assets/images/resources/banner-moc-1-1.png";
import BannerImageMob from "../assets/images/app-shots/banner-mob.png";

const Banner = () => {
  const [open, setOpen] = useState({
    isOpen: false,
  });
  const openModal = () => {
    setOpen({
      isOpen: true,
    });
  };

  return (
    <section className="banner-one" id="home">
      <img
        src={BannerShape}
        className="banner-one__bg-shape-1"
        alt="awesome post"
      />
      <div
        className="banner-one__bg"
        style={{ backgroundImage: `url(${BannerBG})` }}
      ></div>
      <div className="container">
        <ModalVideo
          channel="vimeo"
          isOpen={open.isOpen}
          videoId="935267054"
          onClose={() => setOpen({ isOpen: false })}
        />
        {/* <div
          onClick={openModal}
          onKeyDown={openModal}
          role="button"
          tabIndex="0"
          className="banner-one__video video-popup"
        >
          <i className="fa fa-play"></i>
        </div> */}
        {/* <div className="banner-one__moc">
          <img
            src={BannerImageMob}
            width={403}
            height={702}
            className="wow fadeInUp"
            data-wow-duration="1500ms"
            alt="awesome post"
          />
        </div> */}
        <div className="row">
          <div className="col-lg-7">
            <div className="banner-one__content">
              {/* <form
                className="banner-one__mc-form mc-form"
                data-url="MAILCHIMP__POPUP__FORM__URL"
              >
                <input type="text" name="email" placeholder="Email address" />
                <button type="submit" className="thm-btn banner-one__mc-btn">
                  <span>Free Trial</span>
                </button>
              </form> */}
              <div className="mc-form__response"></div>
              <h3>
                India's Most Significant Consultation And Emotional Wellness
                Platform.
              </h3>
              <p>
                The app makes your life easier by ensuring the best <br />{" "}
                consultation service.
              </p>
              <div className="banner-one__moc">
                <img
                  src={BannerImageMob}
                  width={403}
                  height={702}
                  className="wow fadeInUp"
                  data-wow-duration="1500ms"
                  alt="awesome post"
                />
              </div>
              {/* <a
                href="https://play.google.com/store/apps/details?id=com.mozhi.mozhiapp"
                target="_blank"
                className="thm-btn banner-one__btn"
              >
                <span>Download App</span>
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
